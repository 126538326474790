import React from "react";
import {
  FaUsers,
  FaChalkboardTeacher,
  FaGraduationCap,
  FaSeedling,
  FaProjectDiagram,
} from "react-icons/fa";

const Elevate = () => {
  return (
    <div className="elevate-container">
      <h2 style={{ textAlign: "center", position: "relative" }}>
        Elevate
        <span className="elevate-title"></span>
      </h2>
      <p style={{margin: "30px 0px"}}>"Empowering Talent, Shaping Tomorrow"</p>
      <div className="elevate-sections">
        <div className="elevate-item">
          <FaUsers className="icon" />
          <h3>Hire Motivated Engineers</h3>
          <p>
            We hire the most driven engineers seeking the right opportunity for
            growth and success.
          </p>
        </div>
        <div className="elevate-item">
          <FaChalkboardTeacher className="icon" />
          <h3>Free Training Programs</h3>
          <p>
            We offer free training programs to sharpen skills and prepare for
            real-world challenges.
          </p>
        </div>
        <div className="elevate-item">
          <FaGraduationCap className="icon" />
          <h3>Access to Research</h3>
          <p>
            Our engineers gain access to cutting-edge research and real-world
            projects.
          </p>
        </div>
        <div className="elevate-item">
          <FaSeedling className="icon" />
          <h3>Individual Mentoring</h3>
          <p>
            Each team member receives personalized mentoring and constructive
            feedback to ensure their growth.
          </p>
        </div>
        <div className="elevate-item">
          <FaProjectDiagram className="icon" />
          <h3>Proven Growth</h3>
          <p>
            We have helped 5 developers grow by 40% or more, and we continue to
            mentor interns in AI skills.
          </p>
        </div>
        <div className="elevate-item">
          <FaChalkboardTeacher className="icon" />
          <h3>Prioritize Employee Growth</h3>
          <p>
            We prioritize employee growth through targeted learning,
            assessments, and project assignments.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Elevate;
