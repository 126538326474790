import React from "react";
import { Link } from "react-router-dom";

const Create = () => {
  return (
    <div className="create-container">
      <h2 style={{ textAlign: "center", position: "relative" }}>
        Create
        <span className="create-title"></span>
      </h2>
      <p style={{ margin: "30px 0px" }}>
        "Crafting Intelligent Solutions for a Smarter World"
      </p>
      <div className="create-sections">
        <div className="create-item">
          <img
            alt="waldai"
            src="img/portfolio/waldai.svg"
            style={{ width: "200px", height: "50px" }}
            className="portfolioImage"
          />
          <p>
            Partnered with Wald.ai to develop innovative AI solutions,
            transforming industries with intelligent automation.
          </p>
        </div>
        <div className="create-item">
          <img
            alt="pnutx"
            src="img/portfolio/pnut.svg"
            style={{ width: "200px", height: "50px" }}
            className="portfolioImage"
          />
          <p>
            Collaborated with PNUTX to develop AI-driven solutions for
            agriculture, enabling growth prediction and price forecasting, while
            enhancing operational efficiency and security.
          </p>
        </div>
        <div className="create-item">
          <img
            alt="copal.ai"
            src="img/portfolio/copal.svg"
            style={{ width: "200px", height: "50px" }}
            className="portfolioImage"
          />
          <p>
            Worked with Copal.ai to build advanced AI platforms that focus on
            data privacy, ethics, and consent management.
          </p>
        </div>
        <div className="create-item">
          <img
            alt="alea"
            src="img/portfolio/alea-logo.svg"
            style={{ width: "200px", height: "50px" }}
            className="portfolioImage"
          />
          <p>
            Collaborated with Alea to develop AI-driven systems, including a
            robust RAG (Retrieval-Augmented Generation) system for efficient
            note searching and AI-powered question generation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Create;
