import React from "react";
import { FaBrain, FaFileAlt, FaSeedling, FaUniversity } from "react-icons/fa";
import { Link } from "react-router-dom";

const Innovate = () => {
  return (
    <div className="innovate-container">
      <h2 style={{ textAlign: "center", position: "relative" }}>
        Innovate
        <span className="innovate-title"></span>
      </h2>
      <p style={{ margin: "30px 0px" }}>
        "Innovating with Knowledge, Shaping the Future with AI"
      </p>
      <div className="innovate-sections">
        <div className="innovate-item">
          <FaUniversity className="icon" />
          <h3>University Collaborations</h3>
          <p>Engaged with top universities in cutting-edge AI research:</p>
          <ul>
            <li>University of Erlangen–Nuremberg, Germany</li>
          </ul>
        </div>

        <div className="innovate-item">
          <FaFileAlt className="icon" />
          <h3>AI Research Publications</h3>
          <p>
            Published multiple research papers in renowned international
            conferences.
          </p>
        </div>

        <div className="innovate-item">
          <FaBrain className="icon" />
          <h3>Patented AI Solutions</h3>
          <p>
            Our research work with Wald.ai resulted in a groundbreaking patent
            for AI-based solutions.
          </p>
        </div>

        <div className="innovate-item">
          <FaSeedling className="icon" />
          <h3>AI in Agriculture</h3>
          <p>
            Utilized AI methods for crop yield and price prediction, improving
            agricultural productivity.
          </p>
        </div>
      </div>
      <div>
        <h2
          style={{
            textAlign: "center",
            position: "relative",
            margin: "50px 0px",
          }}
        >
          RESEARCH
          <span className="innovate-title"></span>
        </h2>
      </div>
      <div class="paper-container">
        <Link to="https://gl.mathhub.info/Papers/23-mathui-active-documents/-/blob/main/active_documents.pdf">
          <div class="paper-box">
            <h2 class="paper-title">
              Adaptive Learning Systems in Programming Education: A Prototype
              for Enhanced Formative Feedback
            </h2>
            <p>
              Dominic Lohr¹, Marc Berges¹, Abhishek Chugh², and Michael Striewe³
            </p>
            <p>Best Paper Award</p>
          </div>
        </Link>
        <Link to="https://stexmmt.mathhub.info/:sTeX/browser/fullhtml?archive=Papers/23-CICM-Alea&filepath=paper.xhtml">
          <div class="paper-box">
            <h2 class="paper-title">
              Learning Support Systems based on Mathematical Knowledge
              Management
            </h2>
            <p>
              Marc Berges¹, Jonas Betzendahl¹, Abhishek Chugh², Michael
              Kohlhase¹, Dominic Lohr¹, and Dennis Müller¹
            </p>
          </div>
        </Link>
        <Link to="https://ceur-ws.org/Vol-3377/mathui6.pdf">
          <div class="paper-box">
            <h2 class="paper-title">
              Sophize Markdown and Collaboration Interface
            </h2>
            <p>Abhishek Chugh</p>
          </div>
        </Link>
        <Link to="https://cicm-conference.org/2023/mathui/mathuiPubs/CICM_2023_paper_1615.pdf">
          <div class="paper-box">
            <h2 class="paper-title">
              Presentation of Active Documents in ALeA
            </h2>
            <p>Abhishek Chugh, Michael Kohlhase, and Dennis Müller</p>
          </div>
        </Link>
        <div class="paper-box">
          <h2 class="paper-title">
            Artificial Intelligence Message Sanitization
          </h2>
          <p>Vinay Goel, Ritesh Ahuja, Harish Gudelly, Abhishek Chugh</p>
        </div>
      </div>
      <div>
        <h2
          style={{
            textAlign: "center",
            position: "relative",
            margin: "50px 0px",
          }}
        >
          AWARD
          <span className="innovate-title"></span>
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="img/portfolio/award.jpg"
          alt="innovate"
          className="innovate-img"
        />
      </div>
    </div>
  );
};

export default Innovate;
