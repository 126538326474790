import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import { Navigation } from "./components/navigation";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import Elevate from "./Elevate";
import Innovate from "./Innovate";
import Create from "./Create";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop/>
        <Navigation />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route exact path="/elevate" element={<Elevate />} />
          <Route exact path="/innovate" element={<Innovate />} />
          <Route exact path="/create" element={<Create />} />
        </Routes>
        <Contact data={JsonData.Contact} />
      </Router>
    </>
  );
};

export default App;
