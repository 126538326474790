import React from "react";
import { Link } from "react-router-dom";

const Card = ({ title, description }) => {
  return (
    <Link to={`/${title}`}>
      <div className="card">
        <h2 className="card-title">{title}</h2>
        <p className="card-description">{description}</p>
      </div>
    </Link>
  );
};

const Features = () => {
  return (
    <div style={{ marginTop: "100px" }} id="explore">
      <h2 style={{ textAlign: "center", position: "relative" }}>
        Explore Our Expertise
        <span className="feature-title"></span>
      </h2>
      <p style={{ textAlign: "center" }}>
        "Elevate, Innovate, Create: Your Journey Starts Here."
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
          flexWrap: "wrap",
          marginTop: "50px",
        }}
      >
        <Card
          title="Elevate"
          description="Empowering individuals and organizations to reach new heights with personalized growth opportunities."
        />
        <Card
          title="Innovate"
          description="Driving groundbreaking research and development by collaborating with top universities."
        />
        <Card
          title="Create"
          description="Designing intelligent AI solutions that solve complex problems and shape the future."
        />
      </div>
    </div>
  );
};

export default Features;
